@import '../../styles/customMediaQueries.css';

.root {
  display: block;

  flex-shrink: 0;
  padding: 0 24px;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

}

.logo {
  /* If height is increased, change link margins accordingly */
  /* NOTE: extremely wide logos may look bad. This will scale them to container of 24 x 200. */
  height: 24px;
  width: auto;
  max-width: 188px;
  object-fit: contain;
  object-position: center;
  margin: 18px 0;
    width: 100%;
    height: 100%;
    max-width: 229px;
    max-height: 81px;
    @media (max-width: 1600px) {
      max-width: 180px;    
    margin: 10px 0;
    } 
    @media (max-width: 1360px) {
      max-width: 160px;    
    margin: 10px 0;
    } 
    @media (max-width: 955px) {
      max-width: 115px;
      max-height: 40px;     
    margin: 10px 0;
    } 
}
