@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  height: auto;
  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;

  & label {
    color: #3a3d3a;
    opacity: 0.5;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    padding: 0 30px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin: 0 0 6px !important;
}

.passwordWarnHeading {
  color: #ff0000;
  text-align: left;
  margin-bottom: 16px;
}

.logoImage {
  margin-top: 30px;
}

.signUpWrapper {
  display: flex;
  /* padding: 0px 0 49px; */
  max-width: 1517px;
  margin: 0 auto;
  gap: 120px;

  @media (max-width: 1600px) {
    padding: 0px 60px;
  }

  @media (max-width: 1500px) {
    gap: 70px;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 0px 0px;
    gap: 32px;
  }

  & .leftContent {
    width: 45%;

    @media (max-width: 1023px) {
      width: 100%;
    }

    & h1 {
      font: normal normal 900 80px/90px Roc Grotesk;
      letter-spacing: 0px;
      color: #3a3d3a;

      @media (max-width: 1260px) {
        font: normal normal 900 60px/70px Roc Grotesk;
      }

      @media (max-width: 767px) {
        font: normal normal 900 42px/52px Roc Grotesk;
      }
    }

    & p {
      font: normal normal normal 20px/32px Roc Grotesk;
      letter-spacing: 0px;
      color: #3a3d3a;
      max-width: 581px;

      @media (max-width: 1023px) {
        font: normal normal normal 16px/20px Roc Grotesk;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  & .rightContent {
    width: 100%;

    @media (max-width: 1023px) {
      width: 100%;
    }

    &>div {
      width: 100%;
      margin: 0 0 12px;
      max-width: 718px;
      margin-left: auto;
      margin-right: auto;

      & input {
        background-color: transparent;
        border-radius: unset;
        border: none;
        border-bottom: 2px solid hwb(120 23% 76% / 0.507);
        max-width: 718px;
        margin: 0 auto;
        color: #3a3d3a;

        &::placeholder {
          color: #3a3d3a;
        }

        @media (min-width: 1024px) {
          padding-left: 36px;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &>div {
        max-width: 718px;
        margin: 0 auto;
      }

      & button {
        width: 204px;
        height: 56px;
        margin: 58px auto 0;
        background: #bf5c3a;
        border-radius: 5px;
        font: normal normal bold 20px/36px Roc Grotesk;
        letter-spacing: 0px;
        color: #ffffff;
      }

      & label {
        /* display: none; */
      }

      & :last-child label {
        display: flex;
        /* margin: 36px 0 16px; */
      }
    }
  }

  & .merchantRight {
    width: 55%;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }
}

.passwordMatch {
  color: green;
  margin-bottom: 6px;
  display: block;
}

.passNotMatch {
  color: red;
  margin-bottom: 6px;
  display: block;
}

.signUpContents {
  text-align: center;

  & img {
    @media (max-width: 767px) {
      width: 160px;
    }
  }

  & h2 {
    font: normal normal 900 44px/66px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    margin: 120px 0 17px;

    @media (max-width: 1550px) {
      margin: 110px 0 17px;
    }

    @media (max-width: 1023px) {
      font: normal normal 900 36px/44px Roc Grotesk;
      margin: 48px 0 17px;
    }
  }

  & p {
    font: normal normal normal 20px/32px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    margin: 0 0 12px;

    @media (max-width: 767px) {
      font: normal normal normal 16px/20px Roc Grotesk;
    }
  }
}

.merchantSignUpWrapper {
  padding: 120px 0 190px;

  @media (max-width: 1600px) {
    padding: 120px 60px;
  }

  @media (max-width: 1360px) {
    padding: 110px 50px;
  }

  @media (max-width: 1023px) {
    padding: 80px 20px;
    text-align: center;
  }

  @media (max-width: 1023px) {
    padding: 48px 20px;
  }

  & .bottomWrapper {
    & div {
      text-align: left;
    }
  }

  & .rightContent {
    &>div {
      margin: 0 0 16px;
      text-align: left;

      & input {
        max-width: 100%;
        padding: 3px 36px;

        @media (max-width: 767px) {
          padding: 3px 16px;
          font-size: 16px;
          line-height: 20px;
        }

        &::placeholder {
          color: #3a3d3a;
          opacity: 0.5;
        }
      }

      & :last-child label {
        margin: 18px 0 16px;
      }

      & button {
        margin: 0;
        margin-top: 10px;

        @media (max-width: 1023px) {
          margin: 0 auto;
          width: 168px;
          height: 48px !important;
        }
      }
    }
  }
}

.termsAndCon {
  margin-top: 12px;
}

.businessRadioContents {
  padding: 0 24px;
  margin: 0 !important;
  text-align: left;

  @media (min-width: 1024px) {
    padding-left: 36px;
  }

  &>span {
    color: #3a3d3a;
    opacity: 0.5;
    font-size: 16px;
    line-height: 20px;
  }

  & .businessRadioBtn {
    display: flex;

    &>div {
      /* max-width: 100% !important; */
      margin: 0 !important;

      &:nth-child(1) {
        margin-right: 52px !important;
      }

      /* pretty radio */
      & input[type='radio'] {
        display: none;
      }

      & input[type='radio']+*::before {
        content: '';
        display: inline-block;
        vertical-align: bottom;
        width: 22px;
        height: 22px;
        margin-right: 5px;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: #707070;
      }

      & input[type='radio']:checked+* {
        color: #3a3d3a;
      }

      & input[type='radio']:checked+*::before {
        background: radial-gradient(#3a3d3a 0%, #3a3d3a 40%, transparent 50%, transparent);
        border-color: #3a3d3a;
      }

      & label {
        & span {
          font: normal normal normal 16px/32px Roc Grotesk;
          letter-spacing: 0px;
          color: #3a3d3a;
        }

        & svg {
          display: none;
        }
      }

      & svg {
        & g {
          & circle {
            &:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.descriptionText {
  font-weight: 400;
  font-size: 15px;
  color: #3a3d3a;
}

.logoImg {
  display: block;
  margin: 0 auto;

  @media (max-width: 767px) {
    width: 160px;
  }
}
.customFields {
  &>div{
    margin-bottom: 12px !important;
  }
}