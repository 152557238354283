@import '../../../../styles/customMediaQueries.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap');

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: 155px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px 120px;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (max-width: 1600px) {
    padding: 12px 60px;
    height: 115px;
  }

  @media (max-width: 1360px) {
    padding: 8px 48px;
  }

  @media (max-width: 1023px) {
    padding: 0 24px;
  }
}

.cardBox {
  position: relative;

  & .cartCount {
    background-color: #e12222;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 1px solid #e12222;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    top: 0;
    position: absolute;
    right: 7px;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
  display: flex;
  align-items: center;
  padding-left: 150px;

  @media (max-width: 1500px) {
    padding-left: 150px;
  }

  @media (max-width: 1360px) {
    padding-left: 100px;
  }

  /* @media (max-width: 1250px) {
    padding-left: 100px;
  }
  @media (max-width: 1100px) {
    padding-left: 80px;
  } */
}

.logoLink:hover {
  border-radius: 0;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 24px;
  cursor: pointer;

  @media (max-width: 1500px) {
    margin-right: 16px;
  }

  @media (max-width: 1200px) {
    margin-right: 16px;
  }
}

.dropdownContent {
  display: block;
  position: absolute;
  top: 50px;
  background-color: #f9f9f9;
  width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 12px 0;
  border: 1px solid #000000;

  @media (min-width: 1600px) {
    top: 68px;
  }

  @media (max-width: 1360px) {
    width: 212px;
  }

  & a {
    color: black;
    padding: 12px 23px;
    text-decoration: none;
    display: block;
    font: normal normal 400 20px/32px Roc Grotesk;

    @media (max-width: 1500px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (max-width: 1100px) {
      font-size: 16px;
      line-height: 20px;
    }

    &:hover {
      background-color: #f1f1f1;
    }
  }
}

.dropdown:hover .dropdownContent {
  display: block;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  border-bottom: 1px solid #8d918d;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  width: 321px;
  height: 30px;

  @media (max-width: 1600px) {
    width: 268px;
  }

  @media (max-width: 1100px) {
    width: 240px;
  }

  &>div {
    position: absolute;
    left: 0;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 1600px) {
    padding: 0 12px 0 12px;
  }

  @media(max-width:1100px) {
    padding: 0;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }

  & span {
    font-weight: bold;
    font: normal normal bold 20px/32px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;

    @media (max-width: 1500px) {
      font-size: 18px;
      font-weight: 22px;
    }

    @media (max-width: 1100px) {
      font-size: 14px;
      font-weight: 20px;
    }
  }
}

.rigthLinkWrapper {
  margin: 6px 0 4px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: 1360px) {
    margin: 6px 0 0;
  }

  @media (min-width: 1600px) {
    margin-top: 14px;
  }

  & a {
    &>span {
      font: normal normal bold 20px/32px Noto Sans;
      letter-spacing: 0px;
      color: #3a3d3a;
      margin: 12px 0 24px;

      @media (max-width: 1600px) {
        margin: 12px 0 12px;
      }

      @media (max-width: 1100px) {
        font-size: 16px;
        font-weight: 20px;
      }
    }
  }
}

.authenticateRightWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & a {
    &>span {
      margin: 0;

      @media (max-width: 1500px) {
        margin: 0;
      }
    }
  }
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0 16px;
  text-decoration: inherit;

  @media (max-width: 1600px) {
    margin: 12px 0 8px;
  }
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;
  justify-content: flex-end;

  &:hover {
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 0px 0 0px;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  cursor: pointer;
  color: var(--colorGrey700);

  &:hover {
    text-decoration: none;
  }

  & svg {
    @media (max-width: 1600px) {
      width: 42px;
      height: 42px;
    }
  }
}

.outsideClick {
  display: block;
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  padding: 0px 38px;
  background: #bf5c3a;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1600px) {
    padding: 0px 21px;
    height: 48px;
  }

  @media (max-width: 1300px) {
    height: 42px;
  }

  &:hover {
    text-decoration: none;
  }

  & span {
    color: #ffffff;
    margin: 0 !important;
  }
}

.signup,
.login {
  composes: textSmall from global;

  display: inline-block;
  text-decoration: inherit;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: #BF5C3A;
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: #bf5c3a;
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.loginBtn {
  text-decoration: underline;
  font: normal normal bold 20px/32px Roc Grotesk;
  letter-spacing: 0px;
  color: #bf5c3a;
  cursor: pointer;
}

.leftMenus {
  display: flex;
  align-items: flex-end;
}

.disabledListings {
  cursor: not-allowed;
  pointer-events: none;
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  padding: 4px 24px;
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: unset;
  }

  & span {
    color: #cfcfcf !important;
  }
}

.banner {
  position: absolute;
  background: #bf5c3a;
  top: 96px;
  right: 0;
  width: 100%;
  height: 65px;
  color: white;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  font-size: 17px;
  text-align: unset;
  padding: 10px 20px;
  margin-top: 50px;

  & .accountButton {
    font-size: 17px;
    border: none;
    color: #3a3d3a;
    border-bottom: 1px solid #3a3d3a;
    cursor: pointer;
    padding-left: 8px;
  }
}

.profileApplyButton {
  &>div {
&>span {
  font: normal normal bold 20px/32px Noto Sans;
  letter-spacing: 0px;
  color: #3a3d3a;
  @media(max-width:1100px) {
    font: normal normal bold 16px/32px Noto Sans;
  }
}
  }
}