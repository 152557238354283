.faqWrapper {

  & .titleSection {
    display: flex;
    width: 100%;

    @media (max-width:768px) {
      flex-direction: column-reverse;
    }

    & .imageSection {
      width: 50%;
      background-color: #e1b23d;

      @media (max-width:768px) {
        width: 100%;
      }

      & img {
        height: 500px;
        width: 100%;

        @media (max-width:768px) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  & h4 {
    font: normal normal bold 20px/32px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    margin: 14px 0;

    @media (max-width: 1023px) {
      font-size: 18px;
      line-height: 22px;
      max-width: 90%;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & p {
    text-align: left;
    font: normal normal normal 20px/32px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    padding-right: 50px;
    margin: 0 0 40px;

    @media (max-width: 1023px) {
      padding-right: 20px;
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: 767px) {
      padding-right: 12px;
    }
  }
}

.titleWrapper {
  background: #e1b23d;
  text-align: center;
  padding: 144px 0 55px 126px;
  width: 50%;

  @media (max-width: 1023px) {
  padding: 144px 0 55px 126px;
  }

  @media (max-width:768px) {
    width: 100%; 
    padding: 40px 20px;
  }

  & h1 {
    font: normal normal 900 77px/76px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    margin: 20px auto;
    max-width: 250px;
    text-align: left;

    @media (max-width: 1360px) {
      font-size: 40px;
      line-height: 46px;
    }

    @media (max-width: 1023px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  & h6 {
    font: normal normal 500 32px/44px Roc Grotesk;
    letter-spacing: 0px;
    color: #bf5c3a;
    text-transform: initial;
    font-size: 22px;
    margin: 0 auto;
    padding: 0;
    max-width: 250px;
    text-align: left;

    @media (max-width: 1360px) {
      font-size: 20px;
      line-height: 40px;
    }

    @media (max-width: 1023px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.myAccountWrapper {
  max-width: 930px;
  margin: 0 auto 0px;
  padding-top: 110px;

  @media (max-width: 1023px) {
    padding: 90px 20px 0;
  }

  & h3 {
    font: normal normal 500 32px/44px Roc Grotesk;
    letter-spacing: 0px;
    color: #bf5c3a;
    margin: 0;
    text-align: center;

    @media (max-width: 1023px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
}

:global(.Collapsible__trigger) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  & svg {
    @media (max-width: 1023px) {
      width: 18px;
      height: 18px;
    }
  }
}

:global(.is-closed) {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 18px;
    right: 0;
    height: 24px;
    width: 24px;
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 20 20'%3E%3Cpath fill='%23bf5c3a' d='M17 7v3h-5v5H9v-5H4V7h5V2h3v5h5z'/%3E%3C/svg%3E");
  }
}

:global(.is-open) {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 18px;
    right: 0;
    height: 24px;
    width: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 32 32'%3E%3Cpath fill='%23bf5c3a' d='M25.98 12.896h-20v6.666h20z'/%3E%3C/svg%3E");
  }
}

:global(.Collapsible) {
  border-bottom: 1px solid #000000;
}

.localShoppingWrapper {
  text-align: center;
  padding: 80px 0;
  margin-top: 60px;
  background: #c4d4d2;

  & a:hover {
    text-decoration: unset;
  }

  & h1 {
    font: normal normal 700 26px/50px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 42px;
    }
  }

  & p {
    font: normal normal normal 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    text-align: center;
    padding: 0;
    margin: 18px 0 40px;

    @media (max-width: 767px) {
      margin: 10px 0 20px;
    }
  }

  & button {
    font: normal normal bold 20px/36px Roc Grotesk;
    letter-spacing: 0px;
    color: #ffffff;
    border: none;
    background: #bf5c3a 0% 0% no-repeat padding-box;
    border-radius: 5px;
    height: 56px;
    width: 204px;
    display: initial;
    cursor: pointer;
    margin-top: 30px;

    @media (max-width: 767px) {
      height: 50px;
      width: 180px;
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.tabWrapper {
  max-width: 500px;
  margin-bottom: -45px !important;
  margin: 0 auto;

  & ::-webkit-scrollbar {
    display: none;
  }

  &>div {
    margin: 65px auto 0px;
    /* width: 600px; */
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      padding: 0 24px;
    }
  }

  & .topic {
    font-size: 20px;
    color: #bf5c3a;
  }

  & a {
    font: normal normal 17px/32px Roc Grotesk;
    letter-spacing: 0px;
    /* color: #3a3d3a46; */
    /* width: 33.33%; */
    text-align: center;
    white-space: pre;
    border-right: 1px solid #bf5c3a;
    padding-right: 5px;
    line-height: 18px;

    &:last-child {
      border-right: none;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  & .activeTab {
    color: #bf5c3a;
    /* border-bottom: 5px solid #bf5c3a; */
  }
}

.supportLink {
  margin-top: 20px;
}