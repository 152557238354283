.customField {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.businessRadioContents {
  padding: 0 24px;
  margin: 0 !important;
  text-align: left;

  @media (min-width: 1024px) {
    padding-left: 36px;
  }

  &>span {
    color: #3a3d3a;
    opacity: 0.5;
    font-size: 16px;
    line-height: 20px;
  }
  & .businessRadioBtn {
   display: flex;
 
   &>div {
     /* max-width: 100% !important; */
     margin: 0 !important;
 
     &:nth-child(1) {
       margin-right: 52px !important;
     }
 
     /* pretty radio */
     & input[type='radio'] {
       display: none;
     }
 
     & input[type='radio']+*::before {
       content: '';
       display: inline-block;
       vertical-align: bottom;
       width: 22px;
       height: 22px;
       margin-right: 5px;
       border-radius: 50%;
       border-style: solid;
       border-width: 1px;
       border-color: #707070;
     }
 
     & input[type='radio']:checked+* {
       color: #3a3d3a;
     }
 
     & input[type='radio']:checked+*::before {
       background: radial-gradient(#3a3d3a 0%, #3a3d3a 40%, transparent 50%, transparent);
       border-color: #3a3d3a;
     }
 
     & label {
       & span {
         font: normal normal normal 16px/32px Roc Grotesk;
         letter-spacing: 0px;
         color: #3a3d3a;
       }
 
       & svg {
         display: none;
       }
     }
 
     & svg {
       & g {
         & circle {
           &:nth-child(2) {
             display: none;
           }
         }
       }
     }
   }
 }
}