@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  /* Remove link's hover effect */
  height: 100%;
  display: flex;
  flex-direction: column;

  &>div {
    &:first-child {
      /* flex: 1; */
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.offPrice {
  margin-left: 10px;
  color: #bf5c3a;
  font-weight: 400;

  & .lineThrough {
    text-decoration: line-through;
    margin-right: 10px;
  }
}

.addToCartWrapper {
  display: flex;

  & .title,
  & .authorInfo {
    font: normal normal bold 32px/44px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  &>div {
    padding-bottom: 100% !important;

    & img {
      object-fit: cover;
    }
  }

  &.couponImage {

    &>div {
      padding: 0 !important;

      &>div {
        position: inherit !important;

        &>div {
          position: inherit !important;
          inset: unset !important;
          height: 275px;

          @media (max-width:767px) {
            /* min-height: 100px; */
          }
        }
      }

      & img {
        object-fit: cover;
      }
    }
  }

  @media (--viewportMedium) {
    &:hover {
      /* transform: scale(1.02); */
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
  background-color: #e3b22e;
  padding: 25px 15px;
}

.listingPrice {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin: 4px 0;
}

.listingPriceValue {
  color: #000000;
  text-align: center;
  font-weight: 400;
}

.priceValue {
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  min-height: 50px;

}

.seeCoupon {
  margin-top: 10px;

  & button {
    margin: auto;
    color: #3d3d3d;
    font-weight: 400;
    padding: 5px 15px;
    min-height: auto;
    width: fit-content;
    border-radius: 50px;
    background-color: #c3d2d2;

    &:hover {
      color: #fff;
    }
  }
}

.removeWrapper {
  margin-bottom: -22px;

  & span {
    font: normal normal bold 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #8d918d;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  gap: 6px;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;

  /* Remove default margins from font */
  margin-top: 20px;
  margin-bottom: 0;
  font: normal normal bold 32px/44px Roc Grotesk;
  letter-spacing: 0px;
  color: #3A3D3A;
  text-align: left;

  @media (max-width: 1500px) {
    font: normal normal bold 24px/28px Roc Grotesk;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productContents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  & h6 {
    font: normal normal normal 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #8d918d;
    text-transform: capitalize;
    margin: 0;
    padding: 0;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & h5 {
    font: normal normal bold 20px/30px Roc Grotesk;
    letter-spacing: 0px;
    color: #8d918d;
    margin: 20px 0 0;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      margin: 14px 0 0;
    }
  }
}

.couponNumber {
  color: #BF5C3A;
}

.businessName {
  color: #c05a3a;
  font-weight: 500;
  font-size: 17px;
  padding: 20px 5px;
  text-align: center;
  background-color: #efe9e2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
}

.paymentModal {
  &>div {
    &>div {
      padding: 0 !important;
      flex-basis: 60% !important;

      @media(max-width: 1300px) {
        flex-basis: 80% !important;
      }

      @media(max-width: 1024px) {
        flex-basis: 90% !important;
        height: 140vh !important;
      }

      & button {
        padding: 8px;

        & svg {
          & path {
            fill: #fff;
          }
        }
      }
    }
  }
}

.topbarModal {
  display: flex;
  align-items: center;
  background-color: #e4b32f;

  & .offPercent {
    flex: 2;
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    text-align: center;

    @media(max-width: 768px) {
      font-size: 26px;
      line-height: normal;
      padding-left: 20px;
    }

    @media(max-width: 768px) {
      font-size: 18px;
      line-height: normal;
    }
  }

  & .qrCode {
    padding: 10px;

    & img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
}

.mainWrapperModal {
  display: flex;

  & .paymentLeft {
    width: 95%;

    @media(max-width: 768px) {
      width: 100%;
    }
  }

  & .paymentRight {
    width: 5%;
    background-color: #BF5C3A;

    @media(max-width: 768px) {
      display: none;
    }
  }
}

.bodyModalRow {
  display: flex;
  padding: 24px;
  gap: 20px;

  @media(max-width:768px) {
    flex-wrap: wrap;
  }

  & .bodyLeft {
    width: 30%;

    @media(max-width:768px) {
      width: 50%;
    }

    @media(max-width:460px) {
      width: 100%;
    }

    & .authorAddress {
      color: #BF5C3A;
      font-size: 14px;
      font-weight: 400;
      margin-top: 20px;
      line-height: normal;
    }
  }

  & .bodyRight {
    width: 70%;

    @media(max-width:768px) {
      width: 100%;
    }

    & ul {
      & li {
        display: flex;
        padding: 14px 0 5px;
        align-items: center;
        border-bottom: 2px solid #b8b8b8;

        &:first-child {
          padding-top: 0;
        }

        & .mainTitle {
          width: 200px;
          display: block;
          font-size: 20px;
          font-weight: 400;
          color: #BF5C3A;
          line-height: 23px;
          min-width: 200px;

          @media(max-width: 768px) {
            width: 140px;
            font-size: 14px;
            line-height: 18px;
            min-width: 140px;
          }
        }

        & .detailData {
          font-size: 20px;
          font-weight: 400;
          color: #3d3d3d;
          line-height: 23px;

          @media(max-width: 768px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}



.offerRow {
  display: flex;
  margin-top: 25px;

  @media(max-width:768px) {
    flex-wrap: wrap;
    gap: 20px;
  }

  & .offerDetail {
    width: 80%;

    @media(max-width: 768px) {
      width: 100%;
    }

    & .upperText {
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: #BF5C3A;
      margin-right: 5px;
      line-height: 18px;
    }

    & .normalText {
      font-size: 14px;
      font-weight: 400;
      color: #3d3d3d;
      margin-left: 4px;
      line-height: normal;
    }
  }

  & .offerImage {
    & img {
      width: 120px;
      /* height: 120px; */
      object-fit: contain;
    }
  }
}

.spendDollar {
  & span {
    padding: 0 8px;
  }
} 