.welcomeWrapper{
    background: #f1eae4;
    height: auto;
    padding: 48px 20px 120px;
    position: relative;
    & .closeIcon{
        position: absolute;
        right: 53px;
        top: 41px;
        @media (max-width: 1550px) {
            right: 43px;
            top: 31px;
          }
          @media (max-width: 1023px) {
            right: 33px;
            top: 21px;
          }
          @media (max-width: 767px) {
            right: 15px;
            top: 15px;
          }
          & svg{
            @media (max-width: 1023px) {
              width: 32px;
              height: 32px;
            }
          }
    }
}
.welcomeContents {
  text-align: center;
  max-width: 770px;
  margin: 0 auto;
  & .logoImg {
    display: block;
    margin: 0 auto;
    @media (max-width: 767px) {
        width: 160px;
    }
  }
  & .handMike {
    margin: 156px 0 18px;
    width: 142px;
    height: 75px;
    @media (max-width: 1550px) {
      margin: 65px 0 16px;
    }
    @media (max-width: 1500px) {
      width: 122px;
      height: 64px;
    }
  }
  & h1 {
    font: normal normal 900 80px/90px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    max-width: 440px;
    margin: 0 auto;
    @media (max-width: 1600px) {
      font-size: 70px;
      line-height: 80px;
    }
    @media (max-width: 1600px) {
      font-size: 46px;
      line-height: 54px;
    }
  }
 & .firstThings {
    text-align: center;
    font: normal normal normal 20px/32px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    margin: 29px 0 42px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  & .linkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    & .merchantLink,
    & .shopperLink {
      background: #bf5c3a 0% 0% no-repeat padding-box;
      border-radius: 5px;
      width: 204px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        width: 180px;
        height: 48px;
      }
      & span {
        font: normal normal bold 20px/36px Noto Sans;
        letter-spacing: 0px;
        color: #ffffff;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
    & .shopperLink {
      border: 3px solid #bf5c3a;
      background: transparent;
      cursor: pointer;
      & span {
        color: #bf5c3a;
      }
    }
  }
  & .alreadyAccount {
    margin: 170px 0 6px;
    @media (max-width: 1550px) {
      margin: 60px 0 0px;
    }
    @media (max-width: 767px) {
      margin: 48px 0 12px;
    }
    & h6 {
      font: normal normal normal 20px/32px Roc Grotesk;
      letter-spacing: 0px;
      color: #3a3d3a;
      display: inline-block;
      padding: 0;
      margin: 0;
      margin-right: 10px;
      text-transform: initial;
      @media (max-width: 767px) {
        display: block;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 3px;
      }
    }
    & a {
      font: normal normal 500 20px/32px Roc Grotesk;
      letter-spacing: 0px;
      color: #bf5c3a;
      white-space: pre;
      cursor: pointer;
      text-decoration: underline;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  & p {
    font: normal normal normal 14px/24px Roc Grotesk;
    letter-spacing: 0px;
    color: #3a3d3a;
    margin: 0;
  }
}
