/* @import '../../styles/customMediaQueries.css'; */

.root {
  display: flex;
  position: relative;
  width: 100%;
  padding-right: 15px;
  font-size: 18px;
  color: var(--matterColor);
  line-height: 24px;
  font-weight: 400;

  &:hover {
    text-decoration: none;
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
}

.productDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    /* flex-direction: column; */
    gap: 16px;
    margin: 12px 0;
  }
  & > * {
    /* width: calc(100% / 3); */
  }

  & .price {
    text-align: right;

    @media (min-width: 991px) {
      text-align: right;
    }

    & .priceValue {
      /* @apply --marketplaceH3FontStyles; */
      font-size: 18px;
      line-height: 100%;
      margin-top: 0;
      margin-bottom: 0;

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    & .perUnit {
      /* @apply --marketplaceTinyFontStyles; */
      color: var(--matterColor);
      display: inline-flex;
      align-items: center;
      margin-left: 8px;
      margin-top: 0;
      margin-bottom: 0;

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  & .productDetailsLeft {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 991px) {
      margin-bottom: 15px;
    }

    & .productImg {
      & .aspectRatioWrapper {
        transition: all ease 0.5s;
        border-radius: 10px;
        overflow: hidden;
        overflow: hidden;
        width: 100px;
        height: 100px;

        @media (--viewportMedium) {
          &:hover {
            transition: all ease 0.5s;
            box-shadow: var(--boxShadowListingCard);

            & img {
              transform: scale(1.05);
              transition: all ease 0.5s;
            }
          }
        }

        & img {
          transform: scale(1);
          transition: all ease 0.5s;
        }
      }
    }
  }

  & .productDetailsRight {
    display: flex;

    & .stock {
      display: flex;
      align-items: center;

      & > button {
        padding: 10px;
        background-color: transparent;
        color: var(--matterColor);
        outline: none;
        box-shadow: none;
        line-height: 12px;
        height: 25px;
        font-size: 18px;
        font-weight: 400;
      }

      & .stockCount {
        color: var(--matterColor);
        font-size: 14px;
        border: 1px solid #8d918d;
        border-radius: 10px;
        line-height: 20px;
        width: 36px;
        height: 36px;
        min-width: 36px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
      }
    }
  }
}
.info {
  padding-left: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;

  & .mainInfo {
    display: flex;
    flex-direction: column;

    & .title {
      @apply --marketplaceDefaultFontStyles;
      line-height: 24px;
      text-transform: capitalize;
      color: var(--matterColor);
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 600;
      font-size: 16px;

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }

      & .longWord {
        word-break: break-all;
        word-break: break-word;
        hyphens: auto;
      }
    }

    & .authorInfo {
      @apply --marketplaceTinyFontStyles;
      color: var(--matterColor);
      padding-top: 4px;
      padding-bottom: 4px;
      margin-top: 0;
      margin-bottom: 0;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  & .favorite {
    cursor: pointer;
    font-size: 14px;
    color: var(--matterColor);
    background-color: rgba(210, 210, 210, 0.452);
    display: inline-block;
    padding: 4px 14px;
    border-radius: 10px;
    margin-top: 10px;
    max-width: 80px;
    &:hover {
      /* color: var(--matterColor); */
      background-color: rgba(210, 210, 210, 0.911);
    }

    &:hover {
      & svg {
        color: var(--marketplaceColor);
      }
    }

    & svg {
      margin-top: -2px;
      margin-left: -4px;
      color: var(--matterColorDark);
      cursor: pointer;

      &:hover {
        color: var(--marketplaceColor);
      }

      &.deleteIcon {
        margin-left: 0;
      }
    }
  }
}
.filled {
  fill: var(--marketplaceColor);
}
